<template>
    <section class="content">
        <table class="table table-hover table-stripped table-head-fixed">
            <thead>
                <tr>
                    <th>Url</th>
                    <th>Evento</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr :key="key" v-for="(request, key) in requests">
                    <td>{{helpers.truncate(request.url, 30)}}</td>
                    <td>{{transformEvent(request.event)}}</td>
                    <td>
                        <span 
                            role="button" 
                            class="mr-2"
                            @click="$emit('select-request', request)"
                        >
                            <i class="text-secondary fa fa-eye" title="Visualizar"></i>
                        </span>
                        <span 
                            role="button"
                            @click="$emit('delete-request', {request, key})"
                        >
                            <i class="text-danger fa fa-trash" title="Remover"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="!requests.length" class="d-flex flex-row justify-content-center align-items-center">
            <label class="mt-1">Nenhum Webhook Encontrado.</label>
        </div>
    </section>
</template>

<script>
import { WebhookEventEnum } from "../../../../shared/domain/enum/webhook-event.enum"
import helpers from "../../../../assets/helpers/index"
export default {
    data () {
        return {
            helpers
        }
    },
    methods: {
        transformEvent(event) {
            return WebhookEventEnum[event];
        },
    },
    props: {
        requests: Array
    },

}
</script>

<style>
.content {
    flex: 1;
    height: 500px;
}
</style>